// The path (graphic) is copied from Material-UI.  
// Define a new class so the icon behaves the same way as other icons.

import * as React from 'react';
// import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { SvgIcon, SvgIconProps } from '@mui/material';

type CalendarIconProps = SvgIconProps

const CalendarMonth: React.FC<CalendarIconProps> = (props) => {
  // W & H values seem irrelevant.
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <g>
        <path d="M9 10v2H7v-2h2m4 0v2h-2v-2h2m4 0v2h-2v-2h2m2-7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1m0 16V8H5v11h14M9 14v2H7v-2h2m4 0v2h-2v-2h2m4 0v2h-2v-2h2Z"></path>
      </g>
    </SvgIcon>
  );
};

export default CalendarMonth;