// Copyright (C) 2023-2024 Visual Intelligence All rights reserved.
//
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import container from '@core/di';
import AuthStore from '@core/stores/auth';
import { RefreshFlagContext } from '@modules/Private/Private';

function Timer() {
  const timeout = 1000 * 60 * 30;
  //const timeout = 1000 * 30;  // 30 secs for testing
  //const checkpoints = [ 1000 * 60 * 3, 1000 * 60 * 6, 1000 * 60 * 10, 1000 * 60 * 15, 1000 * 60 * 20, 1000 * 60 * 25];
  const checkpoints = [ 1000 * 60 * 5, 1000 * 60 * 10, 1000 * 60 * 20];
  const [isIdle, setIsIdle] = useState(false);
  const [checkPointIndex, setCheckPointIndex] = useState(0);
  const checkPointIndexRef = useRef(0); // Cheatcode to save checkPointIndex current value, so setInterval() can access

  const { refreshFlag, setRefresh } = useContext(RefreshFlagContext);

  const handleOnAction = () => {
    if (isIdle) {
      setIsIdle(false);
    }
    if (refreshFlag) {
      setRefresh(false);
    }
    setCheckPointIndex(0);
  };

  const handleOnActive = () => {
    // if (isIdle) {
    //   setIsIdle(false);
    // }
    // if (refreshFlag) {
    //   setRefresh(false);
    // }
    // setCheckPointIndex(0);
    handleOnAction();
  };

  const handleOnIdle = () => {
    const authStore = container.get<AuthStore>(AuthStore.diToken);
    authStore.logout(true);
    //setIsIdle(true);
  }

  const {
    reset,
    pause,
    resume,
    getRemainingTime
  } = useIdleTimer({
    timeout,
    onAction: handleOnAction,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  // const handleReset = () => reset();
  // const handlePause = () => pause();
  // const handleResume = () => resume();

  useEffect(() => {
    checkPointIndexRef.current = checkPointIndex;
    console.log(`*** auto-refresh2 #${checkPointIndexRef.current}`);
  }, [checkPointIndex]); // Run when checkPointIndex changes.

  useEffect(() => {
    const id = setInterval(() => {
      const remTime = getRemainingTime();
      const chkpt = checkpoints[checkPointIndexRef.current];
      if (chkpt) {
        if (remTime <= timeout - chkpt) {
          if (!refreshFlag)
            setRefresh(true);
          console.log(`*** auto-refresh1 #${checkPointIndexRef.current} @ ${chkpt/1000} secs`);
          if (checkPointIndexRef.current < checkpoints.length) {
            setCheckPointIndex(prev => prev + 1);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []); // Empty dependency array => run only once during the lifecycle of the component

  // Blank render.
  return (
      <div></div>
  );
}

export default Timer;